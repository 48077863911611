

.layout {

    &-left {
        overflow: auto;
        height: 100vh;
        position: fixed;
        left: 0;

        &-logo {
            height: 64px;
            background: rgba(255, 255, 255, 0.05);
            color: #bfbfbf;
            text-align: center;
            font-size: 24px;
            padding: 12px;
        }

        &-menu {
            .ant-menu-submenu-selected {
                color: #1890ff;
            }
        }
    }

    &-right {
        margin-left: 200px;

        &-header {
            padding: 0;
        }

        &-content {
            margin: 1rem 1.5rem 0;
            overflow: initial;

            min-height: calc(100vh - 150px);

            &-breadcrumb {
                margin-bottom: 1rem;
            }

            &-404 {

            }
        }

        &-footer {
            text-align: center;
        }
    }
}

